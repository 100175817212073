// Custom css for project

@import "~@kopjra/uikit/dist/src/css/mainUIStyle";

body {
  font-family: KTextFont, sans-serif;
  color: $dark;
  font-size: 14px;
  letter-spacing: 0;
  text-align: left;
}

.from {
  font-family: KDefaultFont, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  color: $lightish;
  display: block;
  text-align: center;
}

.big-price {
  font-family: KDefaultFont, sans-serif;
  font-size: 44px;
  letter-spacing: 0;
  color: $primary;
  display: block;
  text-align: center;
  text-transform: uppercase;

  .sub {
    display: inline-block;
    font-family: KDefaultFont, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: $lightish;
    margin-left: 5px;
  }
}

.card-description {
  margin-top: 40px;
}

.card-vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.divider {
  width: 100%;
  height: 1px;
  border-top: $cmp-bg 1px solid;
  display: block;
}

.card-product {
  font-family: KDefaultFont, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: $super-dark;
  letter-spacing: 3.6px;
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.totalLabel {
  letter-spacing: 2.1px;
  color: $lightish;
  font-family: KDefaultFont, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.totalValue {
  font-family: KDefaultFont, sans-serif;
  font-size: 44px;
  color: $primary;
}

.toolbar-cart {
  .payment {
    border: 1px $cmp-bg solid;
    margin: 0 20px;
    text-align: left;
    position: relative;

    .product {
      .central {
        max-width: 270px !important;
      }
    }
  }
  .dropdown-menu, .payment {
    min-width: 360px;
    width: 100%;
    color: $secondary;
    letter-spacing: 0.55px;

    .sectionTitle {
      position: absolute;
      top: -9px;
      background-color: $light;
      left: 10px;
      font-family: KDefaultFont, sans-serif;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0 5px;
    }

    .product {
      display: block;
      width: auto;
      padding: 25px 0;
      margin: 0 25px;
      border-bottom: $cmp-bg solid 1px;

      &.last {
        border-bottom: none;
      }

      img {
        vertical-align: top;
      }

      .central {
        display: inline-block;
        margin-left: 20px;
        max-width: 170px;
      }

      .title {
        display: block;
        font-family: KDefaultFont, sans-serif;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        margin-bottom: 3px;
      }

      .titlish {
        font-family: KDefaultFont, sans-serif;
        font-size: 11px;
        margin-bottom: 30px;
        margin-top: 10px;

        .bold {
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      .details {
        font-size: 11px;
        display: block;
        font-family: KTextFont, sans-serif;

        .upgradable {
          color: $warning;
          text-decoration: line-through;
          margin-right: 5px;
        }

        .non-upgradable {
          color: $danger;
          text-decoration: line-through;
          margin-right: 5px;
        }
      }
    }
  }
}

.cart {
  h6 {
    font-size: 16px;
    margin-top: 10px;
  }

  .k-input-group {
    margin-bottom: 0;
  }

  .btn-xs {
    height: 100%;
  }

  .cart-row {
    display: block;
    width: auto;
    padding: 30px 0;
    margin: 0 10px;
    color: $secondary;
    letter-spacing: 0.7px;

    &.no-margin-top {
      padding-top: 0;
    }

    &.no-margin-bottom {
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .line {
      display: block;
      height: auto;
      font-family: KDefaultFont, sans-serif;
      font-size: 14px;
      text-align: left;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }

      .left {
        text-align: left;
        display: inline-block;
      }

      .center {
        display: inline-block;
        text-align: left;
        vertical-align: top;
      }

      .upper {
        text-transform: uppercase;
      }

      .bold {
        font-weight: bold;
      }

      .solution {
        display: block;
        text-align: center;
        font-size: 12px;
        font-family: KDefaultFont, sans-serif;
        font-weight: bold;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        color: $super-dark;
        margin-right: 50px;
        min-width: 150px;
        max-width: 200px;
      }

      .options {
        text-align: right;
        margin-top: 10px;
        button {
          margin-top: 10px;
          clear: both;
          font-size: 11px;
          color: $primary !important;

          span {
            font-size: 10px;
          }
        }
      }

      .product {
        margin-top: 15px;
        &:first-child {
          margin-top: 0;
        }

        .pimage {
          display: inline-block;
          vertical-align: top;
          margin-right: 20px;
        }

        .title {
          font-weight: bold;
        }

        .details {
          display: inline-block;
          font-family: KDefaultFont, sans-serif;
          font-size: 14px;
          letter-spacing: 0.7px;
          color: $secondary;

          .upgradable {
            color: $warning;
            text-decoration: line-through;
            margin-right: 5px;
          }

          .non-upgradable {
            color: $danger;
            text-decoration: line-through;
            margin-right: 5px;
          }

          .price {
            margin-top: 5px;
            font-family: KTextFont, sans-serif;
            display: block;
          }
        }
      }
    }
  }
}

.single-prod {
  .solution {
    display: block;
    text-align: center;
    font-size: 18px;
    font-family: KDefaultFont, sans-serif;
    font-weight: bold;
    letter-spacing: 3.6px;
    text-transform: uppercase;
    color: $super-dark;
    min-width: 150px;
    max-width: 200px;
  }

  .product {
    margin-top: 5px;
    &:first-child {
      margin-top: 0;
    }

    .details {
      display: inline-block;
      font-family: KDefaultFont, sans-serif;
      font-size: 16px;
      letter-spacing: 0.8px;
      color: $secondary;
      margin-top: 5px;
    }
  }
}

.normalWeight {
  font-weight: normal;
}

.thinWeight {
  font-weight: 300;
}

.bottom-divider {
  border-bottom: $cmp-bg solid 1px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 14px;
  height: 40px;
  font-family: KTextFont, sans-serif;
  background-color: #f6f6f7 !important;
  border: #f6f6f7 solid 1px !important;
}

// TODO: Put in LIB
.card {
  margin-bottom: 40px;
}

.btn.btn-stript {
  padding: 0;
}

h2 {
  line-height: 40px;
}

a.link {
  cursor: pointer;
}

.typeform-popover-button-icon {
  color: $light !important;
}

.typeform-popover-button {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.form-check .form-check-label i.cc:before {
  font-size: 20px;
  top: 0;
}

.tos-check {
  text-align: left !important;
}

.powered {
  margin-top: 10px;
  color: $lightish;
  font-size: 13px;

  position: relative;
  i:before {
    position: relative;
    top: 6px;
    left: 2px;
  }
}

div[class*="container"][class^=iub] {
  flex: none;
}

.iubenda-banner-content {
  p {
    font-family: KTextFont, sans-serif;
    font-size: 12px;
    line-height: 16.8px;
    letter-spacing: normal;
    color: currentColor;
  }
}

.secure {
  color: $secondary;
  opacity: 0.75;

  .lock {
    font-size: 18px;
  }

  .text {
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.7px;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }

  .pstripe {
    margin-left: 25px;
    position: relative;
    top: -3px;
    opacity: 1;
  }

  .cards {
    display: inline-block;
    i {
      &:first-child {
        margin-left: 25px;
      }
      font-size: 28px;
      margin-left: 20px;
      position: relative;
      top: 4px;
    }
  }

  .financedText {
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 40px;
    text-transform: uppercase;
  }

  hr {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .porfesr {
    margin-top: 40px;
    opacity: 1;
  }
}
